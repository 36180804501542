import React, { useRef, useEffect } from "react";
import domToImage from "dom-to-image";
import { useDispatch, useSelector } from "react-redux";
import { updateInvoice } from "../../actions/invoiceActions";
import moment from "moment";
const InvoiceTemplate = ({  invoice ,isTaxCopy}) => {
  const myRef = useRef();

  const dispatch = useDispatch();
  useEffect(() => {
    // You're creating a new IminPrinter instance, but you should check if `window.IminPrinter` is available before using it.
    if (window.IminPrinter) {
      var IminPrintInstance = new window.IminPrinter();
      console.log("IminPrinter", IminPrintInstance);

      try {
        // Create a WebSocket connection
        IminPrintInstance.connect().then(async (isConnect) => {
          // The WebSocket status is linked

          if (isConnect) {
            IminPrintInstance.initPrinter();
            const status = await IminPrintInstance.getPrinterStatus();
            // alert("Printer status: " + JSON.stringify(status));

            const node = myRef.current;

            if (node) {
              domToImage.toJpeg(node).then(function (dataUrl) {
                IminPrintInstance.printSingleBitmap(dataUrl);
                setTimeout(function () {
                  // After the delay, feed paper (assuming 100 is the correct amount)
                  IminPrintInstance.printAndFeedPaper(100);

                  // Cut the paper after feeding
                  IminPrintInstance.partialCut();
                }, 3000);
              });
            }
          }
        });

        dispatch(
          updateInvoice(invoice._id, {
            ...invoice,
            isPrintBill: false,
          })
        );
      } catch (err) {
        alert("Error: IMIN ERROR: " + err.message);
      }
    } else {
      alert("IminPrinter is not available.");
    }
    // dispatch(
    //   updateInvoice(invoice._id, {
    //     ...invoice,
    //     isPrintBill:true
    //   })
    // );
  }, []);
  //  alert("Invoice Template Render");
  return (
    <div
      id="PrintContent"
      style={{
        width: "556px",
        padding: "20px 10px",
        lineHeight: "35px",
        backgroundColor: "white",
        border: "0px solid #FFFFFF",
      }}
    
      ref={myRef}
    >
      <div
        style={{ textAlign: "center", fontSize: "30px", fontWeight: "bold" }}
      >
        {isTaxCopy?"Tax Copy":"Customer Copy"}
      </div>
      <div
        style={{ textAlign: "center", fontSize: "30px", fontWeight: "bold" }}
      >
        MindTree Technologies Co. Ltd.
      </div>
      {/* <div style={{ textAlign: "center", marginTop: "20px", fontSize: "28px" }}>
        REG Nn: 200501519R
        <br />
        GST No.: 20-0501519-R
      </div> */}

      <div style={{ fontSize: "22px", textAlign: "center", width: "100%" }}>
        PHONE: +65 6509 8638
      </div>
      <div
        style={{
          width: "100%",
          borderBottom: "medium dotted #333333",
          height: "5px",
        }}
      ></div>
      <div
        style={{
          marginTop: "30px",
          fontSize: "4rem",
          textAlign: "center",
          fontWeight: "400px",
          width: "100%",
        }}
      >
        ฿{invoice.total.toFixed(2)}
      </div>
      <div
        style={{
          marginTop: "10px",
          fontSize: "18px",
          textAlign: "center",
          fontWeight: "bold",
          width: "100%",
        }}
      >
        Total
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          fontSize: "28px",
          textAlign: "left",
          marginTop: "20px",
          width: "100%",
          borderTop: "medium dotted #333333",
          padding: "10px 0 10px 0",
        }}
      >
        <p>
          <strong>Bill To:</strong>{" "}
          {invoice.client.name ? invoice.client.name : "Cash"}
        </p>
        <p>
          <strong>Inv #:</strong> {invoice.invoiceNumber}
        </p>
      </div>
      <div
        style={{
          fontSize: "28px",
          textAlign: "left",
          paddingTop: "20px",
          width: "100%",
        }}
      >
        <span style={{ fontSize: "28px", width: "50%" }}>
          Date: {moment(invoice.createdAt).format("DD-MM-YYYY")}
        </span>
        <span
          style={{
            fontSize: "28px",
            float: "right",
            textAlign: "right",
            width: "50%",
          }}
        >
          Time: {moment(invoice.createdAt).format("hh:mm a")}
        </span>
      </div>
      <div
        style={{
          width: "100%",
          borderBottom: "medium dotted #333333",
          height: "5px",
        }}
      ></div>
      <div>
        <table
          style={{
            width: "100%",
            borderCollapse: "collapse",
            fontSize: "24px",
          }}
        >
          <thead>
            <tr>
              <th style={{ width: "75%", fontSize: "28px" }}>Items</th>
              <th
                style={{
                  width: "20px",
                  textAlign: "center",
                  fontSize: "28px",
                  right: "30%",
                }}
              >
                Qty
              </th>
              <th
                style={{ width: "50%", textAlign: "right", fontSize: "28px" }}
              >
                Amount
              </th>
            </tr>
          </thead>
        </table>

        <div
          style={{
            width: "100%",
            borderBottom: "medium dotted #333333",
            height: "5px",
          }}
        ></div>
        {invoice?.items?.map((itemField, index) => (
          <table key={index} style={{ width: "100%", fontSize: "24px" }}>
            <tbody>
              <tr>
                <td style={{ width: "75%" }}>{itemField?.itemName}</td>
                <td
                  style={{
                    width: "20px",
                    textAlign: "center",

                    right: "30%",
                  }}
                >
                  {itemField?.quantity}
                </td>
                <td style={{ width: "50%", textAlign: "right" }}>
                  {itemField?.quantity * itemField.unitPrice}
                </td>
              </tr>
            </tbody>
          </table>
        ))}
        <div
          style={{
            width: "100%",
            borderBottom: "medium dotted #333333",
            height: "5px",
          }}
        ></div>
      </div>
      <div
        style={{
          fontSize: "28px",
          paddingTop: "20px",
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <p style={{ fontSize: "28px", width: "62%" }}>Sub-Total </p>
        <p
          style={{
            fontSize: "28px",
          }}
        >
          {invoice.items.reduce((accumulator, item) => {
            return accumulator + parseInt(item.quantity, 10);
          }, 0)}
        </p>
        <p
          style={{
            fontSize: "28px",
          }}
        >
          {invoice.subTotal}
        </p>
      </div>
      {/* <div style={{ fontSize: "28px", paddingTop: "10px", width: "100%" }}>
        <span style={{ fontSize: "28px", width: "60%" }}>Sub-total</span>
        <span
          style={{
            fontSize: "28px",
            float: "right",
            textAlign: "right",
            width: "40%",
          }}
        >
          {invoice.total}
        </span>
      </div> */}
      {/* <div style={{ fontSize: "28px", paddingTop: "20px", width: "100%" }}>
        <span style={{ fontSize: "28px", width: "50%" }}>
          Service charge (10%)
        </span>
        <span
          style={{
            fontSize: "28px",
            float: "right",
            textAlign: "right",
            width: "40%",
          }}
        >
          S$6.10
        </span>
      </div> */}
      { invoice?.discount?( <div style={{ fontSize: "28px", paddingTop: "10px", width: "100%" }}>
        <span style={{ fontSize: "28px", width: "50%" }}> Discount</span>
        <span
          style={{
            fontSize: "28px",
            float: "right",
            textAlign: "right",
            width: "40%",
          }}
        >
          ฿{(invoice.discount).toFixed(2)}
        </span>
      </div>):null}
      <div style={{ fontSize: "28px", paddingTop: "10px", width: "100%" }}>
        <span style={{ fontSize: "28px", width: "50%" }}> Tax</span>
        <span
          style={{
            fontSize: "28px",
            float: "right",
            textAlign: "right",
            width: "40%",
          }}
        >
               ฿{(invoice.vat).toFixed(2)}
        </span>
      </div>
      <div
        style={{
          paddingTop: "20px",
          width: "100%",
          borderBottom: "medium dotted #333333",
          height: "5px",
        }}
      ></div>
      <div
        style={{
          fontSize: "28px",
          paddingTop: "20px",
          width: "100%",
        }}
      >
        <span style={{ fontSize: "28px", width: "50%", fontWeight: "bold" }}>
          Pay Amount(฿)
        </span>
        <span
          style={{
            fontSize: "28px",
            float: "right",
            textAlign: "right",
            width: "50%",
          }}
        >
          <strong>฿{invoice.total.toFixed(2)}</strong>
        </span>
      </div>
      <div
        style={{
          fontSize: "28px",

          width: "100%",
        }}
      >
        <span style={{ fontSize: "24px", width: "50%", fontWeight: "bold" }}>
          Pay Mode
        </span>
        <span
          style={{
            fontSize: "28px",
            float: "right",
            textAlign: "right",
            width: "50%",
          }}
        >
          <strong>{invoice.paymentMode}</strong>
        </span>
      </div>
      {invoice.paymentMode === "Cash" &&<>
      <div
        style={{
          fontSize: "28px",

          width: "100%",
        }}
      >
        <span style={{ fontSize: "24px", width: "50%"}}>
          Received Amount
        </span>
        <span
          style={{
            fontSize: "28px",
            float: "right",
            textAlign: "right",
            width: "50%",
          }}
        >
          {invoice.receivedAmount}
        </span>
      </div>
      <div
        style={{
          fontSize: "28px",

          width: "100%",
        }}
      >
        <span style={{ fontSize: "24px", width: "50%"}}>
          Change
        </span>
        <span
          style={{
            fontSize: "28px",
            float: "right",
            textAlign: "right",
            width: "50%",
          }}
        >
          {invoice.change}
        </span>
      </div>
      </>}
      
      <div
        style={{
          paddingTop: "20px",
          width: "100%",
          borderBottom: "medium dotted #333333",
          height: "5px",
        }}
      ></div>
      <div
        style={{
          fontSize: "28px",
          paddingTop: "20px",
          width: "100%",
          textAlign: "center",
          fontWeight: "bold",
        }}
      >
        THANK YOU
      </div>
      <div
        style={{
          fontSize: "24px",
          paddingTop: "20px",
          width: "100%",
          textAlign: "center",
          fontStyle: "italic",
          fontWeight: "bold",
        }}
      >
        Powered By : MindTree Technologies Co. Ltd.
      </div>
    </div>
  );
};

export default InvoiceTemplate;
