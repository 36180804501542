import React, { useState, useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import FileUpload from "./FileUpload";
import { ItemContext } from "../../Context/ItemContext";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { createItem, updateItem } from "../../actions/itemActions";
import { useSnackbar } from "react-simple-snackbar";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import PhotoCamera from "@material-ui/icons/PhotoCamera";
import { StockContext } from "../../Context/StockContext";
import {
  createCategory,
  getAllCategories,
  deleteCategory,
} from "../../actions/categoryActions";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Container,
  FormControlLabel,
  Switch,
  FormGroup,
  Radio,
  FormHelperText
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    backgroundColor: "#1976D2",
    marginLeft: 0,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: "white",
  },
  inputStyle: {
    width: "100%",
    fontFamily: "kruti-101",
  },
  formControl: {
    width: "100%",
  },
  container: {
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
    border: "1px solid #ccc",
    borderRadius: "8px",
    padding: "10px",
  },
  radioLabel: {
    margin: theme.spacing(1, 0),
    display: "block",
  },
  input: {
    display: "none",
  },
}));

const DialogTitle = (props) => {
  const { children, onClose, ...other } = props;
  const classes = useStyles();
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
};

const DialogContent = (props) => {
  const classes = useStyles();
  return <MuiDialogContent {...props} />;
};

const DialogActions = (props) => {
  const classes = useStyles();
  return <MuiDialogActions {...props} />;
};

const AddItem = ({ setOpen, open }) => {
  const classes = useStyles();
  const location = useLocation();
  const profileDetail = JSON.parse(localStorage.getItem("profileDetail"));
  const profile = JSON.parse(localStorage.getItem("profile"));
  const [itemData, setItemData] = useState({
    itemName: "",
    category: {
      categoryName: "",
      categoryId: "",
    },
    unitPrice: "",
    costPrice: "",
    barcode: "",
    unit: "",
    itemId: [],
    img: null,
    toggleStock: false,
    isStaffReq: false,
    stockQty: {
      initialQty: 0,
      currentQty: 0,
      minQty: 0,
      maxQty: 0,
    },
    vat: profile?.userProfile?.commonVatRate || "",
  });
  const { itemId, setItemId } = useContext(ItemContext);
  const dispatch = useDispatch();
  const item = useSelector((state) =>
    itemId ? state.items.items.find((i) => i._id === itemId) : null
  );

  const { categories } = useSelector((state) => state.categories);
  const [isEditMode, setIsEditMode] = useState(false); // New state variable
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("profile")));
  const [openSnackbar, closeSnackbar] = useSnackbar();
  const [errors, setErrors] = useState({});
  const { stock, trackStock, setTrackStock } = useContext(StockContext);
  const [option, setOption] = useState("");
  const handleTrackStockChange = (event) => {
    setTrackStock(event.target.checked);
    setItemData({
      ...itemData,
      toggleStock: event.target.checked,
    });
  };
  const handleIsStaffRequiredChange = (event) => {
    // setTrackStock(event.target.checked);
    setItemData({
      ...itemData,
      isStaffReq: event.target.checked,
    });
  };
  useEffect(() => {
    if (!categories.length) {
      dispatch(getAllCategories());
    }
  }, [dispatch, categories]);
  useEffect(() => {
    if (itemId) {
      setOption("manualAdd");
    } else {
      setOption("fileUpload");
    }
  }, [itemId]);
  const handleSubmitItem = (e) => {
    debugger;
    e.preventDefault();
    if (validateForm()) {
      if (itemId) {
        dispatch(updateItem(itemId, itemData, openSnackbar));
      } else {
        const updatedItemData = { ...itemData };

        updatedItemData.vat = profile?.userProfile?.commonVatRate;

        setItemData(updatedItemData);
        dispatch(createItem(updatedItemData, openSnackbar));
      }
      clear();
      handleClose();
    }
  };

  const handleChange = (event) => {
    const { name, value, type } = event.target;
    let validatedValue = value;

    // Validate for positive numbers

    validatedValue = Math.max(0, Number(value)); // Ensures positive number

    if (name === "initialQty" || name === "minQty" || name === "maxQty") {
      setItemData((prevData) => ({
        ...prevData,
        stockQty: {
          ...prevData.stockQty,
          [name]: validatedValue,
          currentQty:
            name === "initialQty"
              ? validatedValue
              : prevData.stockQty.currentQty,
        },
      }));
    } else if (type === "number") {
      setItemData({
        ...itemData,
        [name]: validatedValue,
      });
    } else {
      setItemData({
        ...itemData,
        [name]: value,
      });
    }
  };

  function handleFileChange(event) {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const base64ImageData = e.target.result;
        setItemData({ ...itemData, img: base64ImageData });
      };

      reader.readAsDataURL(file);
    }
  }

  const validateForm = () => {
    let isValid = true;
    const newErrors = {};

    if (itemData.itemName.trim() === "") {
      newErrors.itemName = "Item name is required";
      isValid = false;
    }
    if (itemData.unitPrice.trim() === "") {
      newErrors.unitPrice = "Unit price is required";
      isValid = false;
    }
    // if (itemData.barcode.trim() === "") {
    //   newErrors.barcode = "barcode is required";
    //   isValid = false;
    // }
    // if (itemData.unit.trim() === "") {
    //   newErrors.unit = "unit is required";
    //   isValid = false;
    // }

    setErrors(newErrors);
    return isValid;
  };

  const clear = () => {
    setItemData({
      itemName: "",
      category: "",
      categoryId: "",
      unitPrice: "",
      unit: "",
      itemId: [],
      img: null,
      stockQty: null,
      vat: "",
    });
    setErrors({});
    setTrackStock(false);
  };
  useEffect(() => {
    if (item) {
      // When editing an item, set the form data and switch to edit mode
      setItemData(item);
      setIsEditMode(true);
    } else {
      // When opening for a new item, reset the form and switch to add mode
      clear();
      setIsEditMode(false);
    }
  }, [item, open]);

  useEffect(() => {
    setUser(JSON.parse(localStorage.getItem("profile")));
  }, [location]);

  // useEffect(() => {
  //   var checkId = user?.result?._id;
  //   if (checkId !== undefined) {
  //     setItemData({ ...itemData, itemId: [checkId] });
  //   } else {
  //     setItemData({ ...itemData, itemId: [user?.result?.googleId] });
  //   }
  // }, [location]);

  const handleClose = () => {
    setItemData({
      itemName: "",
      category: "",
      categoryId: "",
      unitPrice: "",
      costPrice: "",
      unit: "",
      itemId: [],
      img: null,
      vat: "",
    });
    setErrors({});
    setOpen(false);
  };
  const handleOptionChange = (selectedOption) => {
    setOption(selectedOption);
  };
  return (
    <div>
      <Dialog
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            handleClose();
          }
        }}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
          style={{
            paddingLeft: "20px",
            color: "white",
            backgroundColor: "#ef7f1a",
          }}
        >
          {itemId ? "Edit Item" : "New Item"}
        </DialogTitle>
        <DialogContent dividers>
          {!itemId && (
            <FormGroup row>
              <FormControlLabel
                className={classes.radioLabel}
                control={
                  <Radio
                    checked={option === "fileUpload"}
                    onChange={() => handleOptionChange("fileUpload")}
                    value="fileUpload"
                    color="primary"
                  />
                }
                label="Upload through imported file"
              />
              <FormControlLabel
                className={classes.radioLabel}
                control={
                  <Radio
                    checked={option === "manualAdd"}
                    onChange={() => handleOptionChange("manualAdd")}
                    value="manualAdd"
                    color="primary"
                  />
                }
                label="Manually add the file"
              />
            </FormGroup>
          )}
          {option === "fileUpload" && (
            <FileUpload setOpen={setOpen} open={open} />
          )}
          {option === "manualAdd" && (
            <div>
              <Container className={classes.container}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Name"
                      variant="outlined"
                      className={classes.inputStyle}
                      name="itemName"
                      type="text"
                      required
                      onChange={handleChange}
                      value={itemData.itemName}
                      error={!!errors.itemName} // Check if error exists
                      helperText={errors.itemName} // Display error message
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                    >
                      <InputLabel id="demo-simple-select-outlined-label">
                        Category
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={
                          itemData.category ? itemData.category.categoryId : ""
                        }
                        onChange={(e) => {
                          const selectedCategoryId = e.target.value;
                          const selectedCategory = categories.find(
                            (category) => category._id === selectedCategoryId
                          );

                          setItemData({
                            ...itemData,
                            category: {
                              categoryName: selectedCategory
                                ? selectedCategory?.categoryName
                                : "",
                              categoryId: selectedCategoryId,
                            },
                          });
                        }}
                        label="Category"
                      >
                        {categories.map((category, index) => (
                          <MenuItem key={index} value={category._id}>
                            {category.categoryName}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Sales Rate"
                      variant="outlined"
                      className={classes.inputStyle}
                      name="unitPrice"
                      type="text"
                      required
                      onChange={handleChange}
                      value={itemData.unitPrice}
                      error={!!errors.unitPrice} // Check if error exists
                      helperText={errors.unitPrice} // Display error message
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Purchase Rate"
                      variant="outlined"
                      className={classes.inputStyle}
                      name="costPrice"
                      type="number"
                      onChange={handleChange}
                      value={itemData.costPrice}
                      error={!!errors.costPrice} // Check if error exists
                      helperText={errors.costPrice} // Display error message
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Barcode"
                      variant="outlined"
                      className={classes.inputStyle}
                      name="barcode"
                      type="number"
                      onChange={handleChange}
                      value={itemData.barcode}
                      error={!!errors.barcode} // Check if error exists
                      helperText={errors.barcode} // Display error message
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl
                      variant="outlined"
                      className={classes.inputStyle}
                      error={!!errors.unit} // Highlight field in red if there's an error
                      fullWidth
                    >
                      <InputLabel id="unit-select-label">Unit</InputLabel>
                      <Select
                        labelId="unit-select-label"
                        id="unit-select"
                        name="unit"
                        value={itemData.unit}
                        onChange={handleChange}
                        label="Unit"
                      >
                        {/* Add options as MenuItem */}
                        <MenuItem value="kg">Kilogram (kg)</MenuItem>
                        <MenuItem value="lb">Pound (lb)</MenuItem>
                        <MenuItem value="g">Gram (g)</MenuItem>
                        {/* Add more options as required */}
                      </Select>
                      {/* Display error message */}
                      {!!errors.unit && (
                        <FormHelperText>{errors.unit}</FormHelperText>
                      )}
                    </FormControl>
                  </Grid>

                  {(profileDetail?.vatOptions === "itemBased" ||
                    profile.userProfile.vatOptions === "itemBased") &&
                    (!profileDetail || profileDetail.vatType !== "common") && (
                      <Grid item xs={12} sm={6}>
                        <TextField
                          label="Vat"
                          variant="outlined"
                          className={classes.inputStyle}
                          name="vat"
                          type="number"
                          onChange={handleChange}
                          value={itemData.vat}
                          error={!!errors.vat} // Check if error exists
                          helperText={errors.vat} // Display error message
                        />
                      </Grid>
                    )}

                  <Grid item xs={12} sm={6}>
                    {!itemData.img && (
                      <>
                        <input
                          accept="image/*"
                          className={classes.input}
                          onChange={handleFileChange}
                          id="icon-button-file"
                          type="file"
                        />
                        <label htmlFor="icon-button-file">
                          <IconButton
                            color="primary"
                            aria-label="upload picture"
                            component="span"
                          >
                            <PhotoCamera />
                          </IconButton>
                        </label>
                      </>
                      // <input
                      //   name="image"
                      //   type="file"

                      //   value=""
                      // />
                    )}
                    {itemData.img && (
                      <div style={{ display: "flex" }}>
                        <p style={{ marginTop: "10px" }}>Selected Image: </p>
                        <img
                          src={itemData.img}
                          alt="Image"
                          style={{ width: "50px" }}
                        />
                        <IconButton
                          onClick={() =>
                            setItemData((prevData) => ({
                              ...prevData,
                              img: null,
                            }))
                          }
                        >
                          <CloseIcon />
                        </IconButton>
                      </div>
                    )}
                  </Grid>

                  {/* <Grid item xs={12} sm={6}>
                    <FormControlLabel
                      label="Is Staff Required?"
                      control={
                        <Switch
                          checked={itemData?.isStaffReq}
                          onChange={handleIsStaffRequiredChange}
                          name="isStaffReq"
                          color="primary"
                        />
                      }
                    />
                  </Grid> */}
                </Grid>
              </Container>
              {itemData.category.categoryName != "Services" && (
                <Container
                  className={classes.container}
                  style={{ marginTop: "5px" }}
                >
                  <FormControlLabel
                    label="Track Stock"
                    control={
                      <Switch
                        checked={
                          itemData?.toggleStock
                            ? itemData.toggleStock
                            : trackStock
                        }
                        onChange={handleTrackStockChange}
                        name="trackStock"
                        color="primary"
                      />
                    }
                  />
                  {itemData.toggleStock && (
                    <>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <TextField
                            label="In Stock"
                            variant="outlined"
                            className={classes.inputStyle}
                            name="initialQty"
                            type="number"
                            onChange={handleChange}
                            value={itemData?.stockQty?.initialQty}
                            error={!!errors?.stockQty?.initialQty} // Check if error exists
                            helperText={errors?.stockQty?.initialQty} // Display error message
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            label="Max Qty"
                            variant="outlined"
                            className={classes.inputStyle}
                            name="maxQty"
                            type="number"
                            onChange={handleChange}
                            value={itemData?.stockQty?.maxQty}
                            error={!!errors?.stockQty?.maxQty} // Check if error exists
                            helperText={errors?.stockQty?.maxQty}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            label="Min Qty"
                            variant="outlined"
                            className={classes.inputStyle}
                            name="minQty"
                            type="number"
                            onChange={handleChange}
                            value={itemData?.stockQty?.minQty}
                            error={!!errors?.stockQty?.minQty} // Check if error exists
                            helperText={errors?.stockQty?.minQty}
                          />
                        </Grid>
                      </Grid>
                    </>
                  )}
                </Container>
              )}
              <Container style={{ margin: "10px" }}>
                <Button
                  autoFocus
                  onClick={handleSubmitItem}
                  variant="contained"
                  style={{
                    backgroundColor: "#ef7f1a",
                    color: "white",
                  }}
                >
                  Save Item
                </Button>
              </Container>
            </div>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default AddItem;
